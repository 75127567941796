import { useTranslation } from 'react-i18next'
import React from 'react'
import './style.css'

const Service = (props) => {
    const { t } = useTranslation();
      
    const serviceWrap = [
        {
           sIcon:'fi flaticon-heart',
           title:t("daily_cleaning"),
           des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
        },
        {
           sIcon:'fi flaticon-taxi',
           title:t("airport_taxi"),
           des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
        },
        {
           sIcon:'fi flaticon-eye',
           title:t("places_to_visit"),
           des:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt'
        },
    ]

    return(
        <div className="service-area section-padding">
            <div className="container">
                <div className="col-12">
                    <div className="wpo-section-title">
                        <span>{t("our_services")}</span>
                        <h2>{t("what_we_offer_for_you")}</h2>
                    </div>
                </div>
                <div className="row">
                    {serviceWrap.map((service, sev) => (
                        <div className="col col-lg-4 col-md-6 custom-grid col-12" key={sev}>
                            <div className="service-item">
                                <div className="service-icon">
                                    <i className={service.sIcon}></i>
                                </div>
                                <div className="service-text">
                                    <h2>{service.title}</h2>
                                    <p>{service.des}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Service;