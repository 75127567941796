import { useTranslation } from 'react-i18next'
import React from 'react';
import ContactForm from '../ContactFrom'
import './style.css'
import emailjs from '@emailjs/browser';

const Contactpage = () => {
    const { t } = useTranslation();

    return(
        <div id="Contact" className="contact-area section-padding">
            <div className="container">
                <div className="wpo-contact-info">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="info-item">
                                <div className="info-wrap">
                                    <div className="info-icon">
                                        <i className="fi flaticon-internet"></i>
                                    </div>
                                    <div className="info-text">
                                        <span>{t('hotel_address')}</span>
                                    </div>
                                </div>
                                <h5>{t('safarikova')}</h5>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="info-item">
                                <div className="info-wrap">
                                    <div className="info-icon">
                                        <i className="fi flaticon-email"></i>
                                    </div>
                                    <div className="info-text">
                                        <span>{t('official_mail')}</span>
                                    </div>
                                </div>
                                <h4><a style={{color: '#000'}} href='mailto:office@veneti.rs'>office@veneti.rs</a></h4>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="info-item">
                                <div className="info-wrap">
                                    <div className="info-icon">
                                        <i className="fi flaticon-null-1"></i>
                                    </div>
                                    <div className="info-text">
                                        <span>{t('official_phone')}</span>
                                    </div>
                                </div>
                                <h5><a style={{color: '#000'}} href='tel:+381114038763'>+381 11 403 87 63</a></h5>
                                <h5><a style={{color: '#000'}} href='tel:+381600339331'>+381 60 03 39 331</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="contact-content">
                    <h2>{t('send_a_message')}</h2>
                    <div className="contact-form">
                        <ContactForm emailjs={emailjs}/>
                    </div>
                </div>
                <div className="contact-map">
                    <iframe title="googleMaps" className="gmap_iframe" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Veneti Suites, Šafarikova, Belgrade, Serbia&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                </div>
            </div>
        </div>
     )
        
}

export default Contactpage;
