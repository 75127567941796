import { useTranslation } from 'react-i18next'
import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import './style.css'

const PageTitle = (props) => {
    const location = useLocation();
    const { t } = useTranslation();
    const pages = location.pathname.split('/')
    return(
        <section className="page-title">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                    <h2>{props.pageTitle}</h2>
                        <ol className="breadcrumb">
                            <li><Link to={pages.length > 2 ? "/rooms" : "/home"}>{t(pages.length > 2 ? "Rooms" : "Home")}</Link></li>
                            <li><span>{props.pagesub}</span></li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PageTitle;