import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import './style.css'
import {Link} from 'react-router-dom'
import double_or_twin from '../../images/room/double_or_twin.png'
import limited_offer from '../../images/room/limited_offer.png'
import one_bedroom from '../../images/room/one_bedroom.png'
import junior_suite from '../../images/room/junior_suite.png'
import special_offer from '../../images/room/special_offer.png'

import { useTranslation } from 'react-i18next';

export const roomTypes = ['double_or_twin', 'limited_offer', 'one_bedroom', 'junior_suite', 'special_offer'];
export const rooms = [
    {
        img: double_or_twin,
        type:"double_or_twin",
        price:"€45"
    },
    {
        img: limited_offer,
        type:"limited_offer",
        price:"€45"
    },
    {
        img: one_bedroom,
        type:"one_bedroom",
        price:"€65"
    },
    {
        img: junior_suite,
        type:"junior_suite",
        price:"€55"
    },
    {
        img: special_offer,
        type:"special_offer",
        price:"€50"
    },
];


const RoomSection = (props) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('0');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
    }

    return(
        <section className={`Room-area section-padding ${props.rClass}`}>
            <div className="Room-section">
                <div className="container">
                    <div className="col-12">
                        <div className="wpo-section-title">
                            <span>{t("our_rooms")}</span>
                            <h2>{t("discover_our_rooms")}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12 sortable-gallery">
                            <div className="gallery-filters">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '0' })}
                                            onClick={() => { toggle('0'); }}
                                        >
                                        {t('all')}
                                        </NavLink>
                                    </NavItem>
                                    {roomTypes.map((type, index) => (
                                        <NavItem key={index}>
                                            <NavLink
                                                className={classnames({ active: activeTab === String(index + 1) })}
                                                onClick={() => { toggle(String(index + 1)); }}
                                            >
                                            {t(type)}
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                    
                                </Nav>
                            </div>
                            <div className="gallery-container">
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="0">
                                        {rooms.map((room, rm) => (
                                            <div className="grid" key={rm}> 
                                                <div className="room-item">
                                                    <img src={room.img} alt="" className="img img-responsive"/>
                                                    <div className="room-text-show">
                                                        <h2>{t(room.type)}</h2>
                                                    </div>
                                                    <div className="room-text-hide">
                                                        <h2>{t(room.type)}</h2>
                                                        <span>{room.RoomCount}</span>
                                                        <p>{t(room.type + '_desc')}</p>
                                                        <small>{t('from')}: <span>{room.price}</span> / {t('night')}</small>
                                                        <Link onClick={ClickHandler} className="theme-btn-s2" to={`rooms/${room.type}`}>{t('view_details')}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </TabPane>
                                    {roomTypes.map((type, index) => (
                                        <TabPane key={index} tabId={String(index + 1)}>
                                            {rooms.filter(room => room.type === type).map((room, rm) => (
                                                <div className="grid" key={rm}> 
                                                    <div className="room-item">
                                                        <img src={room.img} alt="" className="img img-responsive"/>
                                                        <div className="room-text-show">
                                                            <h2>{t(room.type)}</h2>
                                                        </div>
                                                        <div className="room-text-hide">
                                                            <h2>{t(room.type)}</h2>
                                                            <span>{room.RoomCount}</span>
                                                            <p>{t(room.type + '_desc')}</p>
                                                            <small>{t('from')}: <span>{room.price}</span> / {t('night')}</small>
                                                            <Link onClick={ClickHandler} className="theme-btn-s2" to={`rooms/${room.type}`}>{t('view_details')}</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </TabPane>
                                    ))}
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RoomSection;