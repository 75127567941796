import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import About from '../../components/about'
import RoomSection from '../../components/RoomSection'
import Counter from '../../components/Counter'
import Testimonial from '../../components/Testimonial'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import { useTranslation } from 'react-i18next';


const AboutPage =() => {
    const { t } = useTranslation();
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={t('about_us')} pagesub={t('about')}/> 
            <About fullText/>
            <RoomSection/>
            <Counter/>
            <Testimonial/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default AboutPage;
