import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from "react-router-dom";

const imagesCountMapper = {
    "double_or_twin": 13,
    "limited_offer": 9,
    "one_bedroom": 12,
    "junior_suite": 13,
    "special_offer": 4
};

const Rooms = () => {
    const { id } = useParams();
    const imageCount = [...Array(imagesCountMapper[id]).keys()];

    const settings = {
        dots: false,
        arrows: true,
        speed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    return (
        <div className="room-slide-area section-padding">
            <div className="Room-carousel">
                <Slider {...settings}>
                    {imageCount.map(key => (    
                        <div key={key} className="item">
                            <div className="destination-item">
                                <div className="destination-img">
                                    <img alt={`room ${key}`} src={require(`../../../images/room/${id}/${key + 1}.png`)} />
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>

    );
}

export default Rooms;