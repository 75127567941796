
import React from "react";
import {useParams} from 'react-router-dom'
import spaceImg from '../../../images/room/space.png'
import ammenitiesImg from '../../../images/room/ammenities.png'
import { useTranslation } from "react-i18next";

export const space = {
    double_or_twin: [
        "Accommodates: 2",
        "Bathrooms: 1",
        "Bedroom: 1",
        "Beds: 1 or 2 ( size 160x200 or 2x 90x200)",
        "Check In: 2:00 p.m.",
        "Check Out: 11:00 a.m.",
        "Property Type: Hotel Room"
    ],
    limited_offer: [
        "Accommodates: 2",
        "Bathrooms: 1",
        "Bedroom: 1",
        "Beds: 1 (160x200)",	
        "Check In: 2:00 p.m.",
        "Check Out: 11:00 a.m.",
        "Property Type: Hotel Room"
    ],
    one_bedroom: [
        "Accommodates: 2",
        "Bathrooms: 1",
        "Bedroom: 1",
        "Beds: 1 queen size bed (160x200) and 1 sofa bed",
        "Check In: 2:00 p.m.",
        "Check Out: 11:00 a.m.",
        "Property Type: Hotel Room"
    ],
    junior_suite: [
        "Accommodates: 2",
        "Bathrooms: 1",
        "Bedroom: 1",
        "Beds: 1 queen size bed (160x200) and 1 sofa bed",
        "Check In: 2:00 p.m.",
        "Check Out: 11:00 a.m.",
        "Property Type: Hotel Room"
    ],
    special_offer: [
        "Accommodates: 2",
        "Bathrooms: 1",
        "Bedroom: 1",
        "Beds: 1 or 2 ( size 160x200 or 2x 90x200)",
        "Check In: 2:00 p.m.",
        "Check Out: 11:00 a.m.",
        "Property Type: Hotel Room"
    ]
};

const ammenities = [
    "free_wifi",
    "air_condition",
    "minibar",
    "flatscreen",
    "safety_deposit_box",
    "electric_kettle_slippers_towels",
    "free_toiletries",
    "free_coffee_tea",
    "parking_extra_fee"
]

const Description = (props) => {
    const { id } = useParams();
    const { t } = useTranslation();

    return(
        <div className="Room-details-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="room-description">
                            <div className="room-title">
                                <h2>{t('description')}</h2>
                            </div>
                            <p className="p-wrap">{t(id + '_desc')}</p>
                            {id === 'special_offer' && (
                                <p>{t('special_offer_desc')}</p>
                            )}
                            <p>{t('standard_facilities_text')}</p>
                        </div>
                        <div className="room-details-service">
                            <div className="row">
                                <div className="room-details-item">
                                    <div className="row">
                                        <div className="col-md-5 col-sm-12 col-12">
                                            <div className="room-d-text">
                                                <div className="room-title">
                                                    <h2>{t('space')}</h2>
                                                </div>
                                                <ul>
                                                    {space[id].map((item, index) => (
                                                        <li key={index}><p>{t(item)}</p></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-7 col-sm-12 col-12">
                                            <div className="room-d-img">
                                                <img src={spaceImg} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-7 col-sm-12 col-12">
                                            <div className="room-d-img">
                                                <img src={ammenitiesImg} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-5 col-sm-12 col-12">
                                            <div className="room-d-text2">
                                                <div className="room-title">
                                                    <h2>{t("ammenities")}</h2>
                                                </div>
                                                <ul>
                                                    {ammenities.map((item, index) => (
                                                        <li key={index}><p>{t(item)}</p></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Description;