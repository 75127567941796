import React from 'react'
import {Link} from 'react-router-dom'
import ab1 from '../../images/about/about.jpg'
import { useTranslation } from "react-i18next";
import './style.css'

const About = (props) => {
    const { t } = useTranslation();
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
    }
    return(
        <div className="wpo-about-area section-padding pb-0">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-12 col-sm-12">
                        <div className="wpo-about-img">
                            <img src={ab1} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 colsm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-section-title">
                                <span>{t('about_us')}</span>
                                <h2>{t('about_us_title')}</h2>
                            </div>
                            <p>{t('about_us_short')}</p>
                            { props.link && (
                                <div className="btns">
                                    <Link onClick={ClickHandler} to="/about" className="theme-btn-s2">{t('about_us_button')}</Link>
                                </div>
                            )}
                        </div>
                    </div>
                    { props.fullText && (
                        <div className='col-12 mt-4 wpo-about-text'>
                            <p dangerouslySetInnerHTML={{__html: t('about_us_text')}}></p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default About;