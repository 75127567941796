
import i18next, { t } from "i18next";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import './style.css'

const SearchSection = (props) => {

    const [startDate,  setStartDate] = useState(new Date());
    const [startDates,  setStartDates] = useState(() => {
        var date = new Date();
        date.setDate(date.getDate() + 1);
        return date;
    });
    const [adults,  setAdults] = useState(2);
    const [children,  setChildren] = useState(0);

    const SubmitHandler = (e) =>{
        e.preventDefault();
        const Difference_In_Time = startDates.getTime() - startDate.getTime();
        const nights = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));
        const nightsString = nights > 0 ? `&nights=${nights}` : '';
        window.location.href = `https://secure.phobs.net/book.php?booking_preview=1&company_id=0c78de4d09d27af75b6782e61cabd2fe&hotel=cdd336bff5124a8f0967c62be99415aa&date=${startDate.toISOString().split('T')[0]}${nightsString}&adults[1]=${adults}$lang=${i18next.language}`;
    }

    return(
        <div className={`wpo-select-section ${props.selectClass}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="wpo-select-wrap">
                            <div className="wpo-select-area">
                                <form onSubmit={SubmitHandler} className="clearfix">
                                    <div className="select-sub">
                                        <span><i className="fi flaticon-calendar"></i>{t("check_in")}</span>
                                        <div className="form-group">
                                            <div id="filterDate">
                                                <div className="input-group date" data-date-format="dd.mm.yyyy">
                                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                                    <div className="input-group-addon">
                                                        <span className="ti-angle-down"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="select-sub">
                                        <span><i className="fi flaticon-calendar"></i>{t("check_out")}</span>
                                        <div className="form-group">
                                            <div id="filterDate2">
                                                <div className="input-group date" data-date-format="dd.mm.yyyy">
                                                    <DatePicker selected={startDates} onChange={(date) => setStartDates(date)} />
                                                    <div className="input-group-addon">
                                                        <span className="ti-angle-down"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="select-sub">
                                        <span> <i className="fi flaticon-user"></i> {t("guests")}</span>
                                        <select value={'0' + adults} className="select wide" onChange={(event) => setAdults(Number(event.currentTarget.value))}>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                            <option>04</option>
                                            <option>05</option>
                                            <option>06</option>
                                        </select>
                                    </div>
                                    <div className="select-sub">
                                        <span> <i className="fi flaticon-user"></i> {t("children")}</span>
                                        <select className="select wide" onChange={(event) => setChildren(Number(event.currentTarget.value))}>
                                            <option value={0}>-</option>
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                            <option>04</option>
                                            <option>05</option>
                                            <option>06</option>
                                        </select>
                                    </div>
                                    <div className="select-sub">
                                        <button className="theme-btn-s2" type="submit">{t("check_availability")}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchSection;