import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Homepage from '../HomePage'
import RoomPage from '../RoomPage'
import RoomSinglePage from '../RoomSinglePage'
import AboutPage from '../AboutPage'
import ContactPage from '../ContactPage' 

      

const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
          <Switch>
            <Route exact path='/' component={Homepage}/>
            <Route path='/home' component={Homepage} />
            <Route path='/rooms/:id' component={RoomSinglePage} />
            <Route path='/rooms' component={RoomPage} />
            <Route path='/about' component={AboutPage} />
            <Route path='/contact' component={ContactPage}/>
          </Switch>
      </Router>
      
    </div>
  );
}

export default AllRoute;
