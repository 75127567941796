import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import man from '../../images/testimonial/man.png'
import woman from '../../images/testimonial/woman.png'
import './style.css'
import { withTranslation } from "react-i18next";


class Testimonial extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: false,
            speed: 3000,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const testimonial = [
            {
                tsImg: man,
                Des: "“Ovde je tako udobno. Odsešću ovde i na mom sledećem putovanju. Svidela mi se soba, jer sam se u njoj osećao kod kuće.”",
                Title:'Milan Jovic',
                Sub: "Kraljevo",
            },
            {
                tsImg: man,
                Des: "“This would be my first place I would recommend. Most definitely exceed my expectations”",
                Title:'Tiago Williams',
                Sub: "Mexico",
            },
            {
                tsImg: woman,
                Des: "“The trip was incredible. I got to see so many places I wouldn't have by myself, me and my husband really enjoyed a lot.”",
                Title:'Lily Meera',
                Sub: "Canada",
            },
            {
                tsImg: man,
                Des: "“My stay exceeded my expectations...room, comfy-like home. All of the extras are here. The business center is a major convenience-very nice”",
                Title:'Benjir Walton',
                Sub: "England",
            }
        ]
        return (
            <div className="testimonial-area section-padding">
                <div className="container">
                    <div className="col-12">
                        <div className="wpo-section-title">
                            <span>{this.props.t('testemonials_title')}</span>
                            <h2>{this.props.t('testemonials_text')}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="testimonial-slider clearfix">
                                <Slider {...settings}>
                                    {testimonial.map((tesmnl, tsm) => (
                                        <div className="grid" key={tsm}>
                                            <div className="ratting">
                                                <ul>
                                                    <li><i className="fi flaticon-star"></i></li>
                                                    <li><i className="fi flaticon-star"></i></li>
                                                    <li><i className="fi flaticon-star"></i></li>
                                                    <li><i className="fi flaticon-star"></i></li>
                                                    <li><i className="fi flaticon-star"></i></li>
                                                </ul>
                                            </div>
                                            <div className="quote">
                                                <p>{tesmnl.Des}</p>
                                            </div>
                                            <div className="client-info">
                                                <div className="client-img">
                                                    <img src={tesmnl.tsImg} alt="" />
                                                </div>
                                                <div className="client-text">
                                                    <h5>{tesmnl.Title}</h5>
                                                    <p>{tesmnl.Sub}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Testimonial);