import { useTranslation } from 'react-i18next'
import React from 'react'

import './style.css'

const Counter = (props) => {
    const { t } = useTranslation();

    const counter = [
        {
          Ficon:'fi flaticon-key',
          heading:t("amazing_rooms"),
          data:"10",
        },
        {
          Ficon:'fi flaticon-staff',
          heading:t("dadicated_staffs"),
          data:"8",
        },
        {
          Ficon:'fi flaticon-calendar',
          heading:t("stays"),
          data:"18000",
        },
        {
          Ficon:'fi flaticon-eye',
          heading:t("places_to_visit"),
          data:"30",
        },
    ]

    return(
      <div className="wpo-counter-area">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="wpo-counter-grids">
                          {counter.map((count, cnt) => (
                              <div className="grid" key={cnt}>
                                  <div className="wpo-counter-icon">
                                      <i className={count.Ficon}></i>
                                  </div>
                                  <div>
                                      <h2>{count.data} +</h2>
                                  </div>
                                  <p>{count.heading}</p>
                              </div>
                          ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Counter;