import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import Hero from '../../components/hero'
import SearchSection from '../../components/SearchSection'
import About from '../../components/about'
import RoomSection from '../../components/RoomSection'
import Testimonial from '../../components/Testimonial'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'

import hero1 from '../../images/slider/slider1.jpg'
import hero2 from '../../images/slider/slider2.jpg'
import hero3 from '../../images/slider/slider3.jpg'
import hero4 from '../../images/slider/slider4.jpg'
import Service from '../../components/Service';
import { useTranslation } from 'react-i18next';


const HomePage =() => {
    const { t } = useTranslation();
    return(
        <Fragment>
            <Navbar/>
            <Hero heroClass={'hero-style-1'} heroImg1={hero1} heroImg2={hero2} heroImg3={hero3} heroImg4={hero4} title={t('slider_title')} text={t('slider_text')} button={t('slider_button')}/>
            <SearchSection/>
            <About link />
            <RoomSection/>
            <Service/>
            <Testimonial/>
            <Footer/> 
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage;