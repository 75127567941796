import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import RoomSingleSection from '../../components/RoomSingleSection'
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

const RoomSinglePage =() => {
    const { t } = useTranslation();
    const { id } = useParams();

    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={t(id)} pagesub={t(id)}/> 
            <RoomSingleSection/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default RoomSinglePage;
