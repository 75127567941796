import React from 'react';
import AllRoute from '../router'
import {ToastContainer} from 'react-toastify';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "home": "Home",
          "rooms": "Rooms",
          "about": "About",
          "contact": "Contact",
          "about_us": "About Us",
          "about_us_title": "A Best Place to Enjoy",
          "about_us_short": "Situated in the real heart of Belgrade, Veneti Suites has an excellent location score of 9.8 acording to Booking.com. Situated in the Stari Grad district in Belgrade, 500 m from Trg Republike Belgrade, Veneti Suites features air-conditioned rooms with free WiFi throughout the property.",
          "about_us_text": "St. Sava Temple is 2 km from Veneti Suites, while Belgrade Fair is 3.2 km away. Belgrade Nikola Tesla Airport is 14 km far from the property.<br/>District Stari Grad, (central district of Belgrade) is a great choice for travellers interested in nightlife, food and history. This is favourite part of Belgrade, according to independent reviews.<br/>This property is also rated for the best value in Belgrade! Guests are getting more for their money when compared to other properties in this city.<br/>Inspiration for the name of we found from the Veneti (in Latin, also Heneti) an Indo-European people who inhabited northeastern Italy, in an area corresponding to the modern-day region of Veneto. The ethnonym Venetī is derived from Proto Indo-European root *wen- ‘to strive; to wish for, to love’.<br/>Historian Herodotus at one point mentions the Veneti of the Adriatic and at another refers in passing to the “Eneti in Illyria” whose supposed marriage customs, he claims, mirrored those of the Babylonians. This led early scholars to seek to link the Veneti with the Illyrians from Balkan peninsula.<br/>Skadarlija, the most beautiful story of old Belgrade. Skadarlija is a vintage street, an urban neighborhood and former municipality of Belgrade. It is located in the center of Belgrade, just few hundred meters away from Veneti Suites, municipality of Stari Grad (Old town) and generally considered the main bohemian quarter of Belgrade, similar to Paris’ Montmartre.<br/> After Kalemegdan, Skadarlija is the second most visited tourist attraction in Belgrade.<br/>This property also has one of the best-rated locations in Belgrade! Guests are happier about it compared to other properties in the area.<br/>Welcome to Veneti Suites, we speak your language!",
          "about_us_button": "More About Us",
          "slider_title": "Veneti Hotel",
          "slider_button": "Book a room",
          "slider_text": "Make Your Life Better and Bright! You must stay with Us!",
          "hotel_address": "Hotel Address",
          "official_mail": "Official Mail",
          "official_phone": "Official Phone",
          "send_a_message": "Send a message",
          "testemonials_title": "What Say Clients",
          "testemonials_text": "Our Clients are Important to Us",
          "our_rooms": "Our Rooms",
          "visit_us": "Visit Us",
          "discover_our_rooms": "Discover our rooms",
          "double_or_twin": "Double or Twin Room",
          "limited_offer": "Limited Offer - Double Room",
          "one_bedroom": "One-Bedroom Suite",
          "from": "From",
          "night": "Night",
          "check_availability": "Check Availability",
          "view_details": "View details",
          "all": "All",
          "description": "Description",
          "space": "Space",
          "ammenities": "Ammenities",
          "junior_suite": "Junior Suite",
          "special_offer": "Special Offer: Double/Twin Room with Round Airport Transfer",
          "double_or_twin_desc_short": "Enjoy our elegant 20 m² guest rooms",
          "double_or_twin_desc": "Enjoy our elegant 20 m² guest rooms, designed in a modern and sophisticated way, and tailor-made for each traveler, private or business.",
          "limited_offer_desc": "Enjoy our elegant 20 m² guest rooms, designed in a modern and sophisticated way, and tailor-made for each traveler, private or business.",
          "one_bedroom_desc": "Relax in our private suite with a living room and one bedroom with a total area of 35 m2.",
          "junior_suite_desc": "With the size of 28 m²,our Junior Suite is designed to provide a complete sense of comfort when you are traveling with a family, or a group.",
          "special_offer_desc": "The offer includes a standard double or twin room and transportation from the airport to the hotel in both directions. You can expect clean, well maintained Mercedes E Class and Škoda Superb vehicles.",
          "special_offer_desc_short": "The offer includes a standard double or twin room and transportation from the airport.",
          "standard_facilities_text": "Standard facilities in all our modern-style units are LCD satellite TV, air conditioning, a safety deposit box and a minibar. The private bathroom comes with a shower,  hairdryer and premium toiletries. All units feature a modern work area with free internet access.",
          "special_offer_desc2": "The offer includes a standard double or twin room and transportation from the airport to the hotel in both directions. You can expect clean, well maintained Mercedes E Class and Škoda Superb vehicles. With our drivers, you will always get a comfortable, stress-free ride. Ride with safety and style.",
          "amazing_rooms": "Amazing Rooms",
          "dadicated_staffs": "Dadicated Staffs",
          "stays": "Stays",
          "places_to_visit": "Places To Visit",
          "daily_cleaning": "Daily Cleaning",
          "airport_taxi": "Airport Transfer",
          "our_services": "Our Services",
          "what_we_offer_for_you": "What We Offer For You",
          "check_in": "Check - In",
          "check_out": "Check - out",
          "guests": "Guests",
          "children": "CHILDREN",
          "free_wifi": "Free Wifi",
          "air_condition": "Air Condition",
          "minibar": "Minibar",
          "flatscreen": "Flat-Screen TV",
          "safety_deposit_box": "Safety deposit box",
          "electric_kettle_slippers_towels": "Electric kettle, Slippers, Towels",
          "free_toiletries": "Free toiletries",
          "free_coffee_tea": "Free coffee and tea",
          "useful_links": "Useful Links",
          "parking_extra_fee": "Parking (extra fee)",
          "Accommodates: 2": "Accommodates: 2",
          "Bathrooms: 1": "Bathrooms: 1",
          "Bedroom: 1": "Bedroom: 1",
          "Beds: 1 or 2 ( size 160x200 or 2x 90x200)": "Beds: 1 or 2 ( size 160x200 or 2x 90x200)",
          "Check In: 2:00 p.m.": "Check In: 2:00 p.m.",
          "Check Out: 11:00 a.m.": "Check Out: 11:00 a.m.",
          "Property Type: Hotel Room": "Property Type: Hotel Room",
          "Beds: 1 (160x200)": "Beds: 1 (160x200)",
          "Beds: 1 queen size bed (160x200) and 1 sofa bed": "Beds: 1 queen size bed (160x200) and 1 sofa bed",
          "First Name": "First Name",
          "Lastname": "Lastname",
          "Email": "Email",
          "Subject": "Subject",
          "Message": "Message",
          "Send Message": "Send Message",
          "safarikova": "11 Šafarikova 1st floor, Belgrade, Serbia",
          "Please enter your name": "Please enter your name",
          "Please enter your email": "Please enter your email",
          "Please enter your subject": "Please enter your subject",
          "Please enter your Lastname": "Please enter your Lastname",
          "Please enter your message": "Please enter your message"
        }
      },
      rs: {
        translation: {
          "home": "Početna",
          "rooms": "Sobe",
          "about": "O Nama",
          "contact": "Kontakt",
          "about_us": "O Nama",
          "about_us_title": "Najbolje mesto za uživanje",
          "about_us_short": "Smešten u samom srcu Beograda, Veneti apartmani imaju odličnu ocenu lokacije od čak 9.8 prema portalu Booking.com. Apartmani se nalaze na opštini Stari Grad, samo 500 metara od Trga Republike, a opremljeni su klimatizovanim sobama, sa bežičnim internetom na čitavom posedu.",
          "about_us_text": "Hram Svetog Save je samo dva kilometra od apartmana, Beogradski sajam 3.2 kilometra, dok je aerodrom Nikola Tesla udaljen 14 kilometara<br/>Opština Stari Grad je idealno mesto za putnike koje interesuje noćni život, hrana i istorija. Ovo je omiljeni deo beograda, prema nezavisnim ocenama.<br/>Veneti apartmani su ocenjeni kao mesto u kome posetioci za uloženi novac dobijaju najveću vrednost, u poređenju sa ostalim smeštajima u Beogradu.<br/>Apartmani su dobili ime po indoevropskom narodu Venecijanci, koji su naseljavali prostor severnoistočne Italije, oko današnje Venecije. Reč veneti je izvedena iz korena reči Wen, koja je označavala težnju za uspehom ili ljubavlju.<br/>Istoričar Herodot u jednom delu pominje Venecijance na Jadranskom moru, a u drugom Veneti Ilirce, a čija je kultura bila slična vizantijskoj. Ovo je nateralo istoričare da traže bliske veze između tadašnjih Vencijanaca i Ilira koji su naseljavali ovo područje.<br/>Skadarlija je najlepša priča Beograda. Skadarlija je prometna ulica, u urbanom komšiluku, bivše sedište grada Beograda. Locirana je u centru grada, samo nekoliko stotina metara od Veneti apartmana, na Starom Gradu, i smatra se boemskim četvrti Beograda, slično kao pariski Monmaartr.<br/> Posle Kalemegdana, Skadarlija je druga najposećenija turistička atrakcija Beograda.<br/>Ovaj hotel ima jednu od najbolje ocenjenih lokacija u Beogradu. Gosti su srećniji kod nas nego u nekim drugim hotelima u okolini.<br/>Dobrodošli u Veneti apartmane, mi pričamo vaš jezik.",
          "about_us_button": "Više o nama",
          "slider_title": "Veneti Hotel",
          "slider_button": "Rezervisati sobu",
          "slider_text": "Učinite svoj život boljim i lepšim! Odsednite kod nas! ",
          "hotel_address": "Adresa hotela",
          "official_mail": "E mail hotela",
          "official_phone": "Broj telefona",
          "send_a_message": "Pišite nam",
          "testemonials_title": "Utisci klijenata",
          "testemonials_text": "Mišljenja naših klijenata su nam važna",
          "our_rooms": "Naše sobe",
          "visit_us": "Posetite nas",
          "discover_our_rooms": "Otkrijte naše sobe",
          "double_or_twin": "Dvokrevetna soba/Soba sa odvojenim",
          "limited_offer": "Limitirana ponuda - Dvokrevetna soba",
          "one_bedroom": "Jednosobni Apartman",
          "from": "Od",
          "night": "Noć",
          "check_availability": "Proveri dostupnost",
          "view_details": "Pogledaj detalje",
          "all": "Sve",
          "description": "Opis",
          "space": "Soba",
          "ammenities": "Pogodnosti",
          "junior_suite": "Junior Apartman",
          "special_offer": "Specijana ponuda - Dvokrevetna soba/Soba sa odvojenim krevetima sa transferom od/do aerodroma",
          "double_or_twin_desc": "Standardna soba, modernog dizajna prostrana je soba veličine 20m² i napravljena po meri svakog putnika.",
          "limited_offer_desc": "Standardna soba, modernog dizajna prostrana je soba veličine 20m² i napravljena po meri svakog putnika.",
          "one_bedroom_desc": "Opustite se u našem privatnom apartmanu sa dnevnom sobom i jednom spavaćom sobom ukupne površine od 35 m².",
          "junior_suite_desc": "Ukoliko putujete s porodicom ili grupom prijatelja, naš Junior apartman je osmišljen da pruži maksimalni osećaj udobnosti. Površine 28 m², sastoji se od velikog bračnog kreveta i kauča na razvlačenje.",
          "special_offer_desc": "Ponuda uključuje standardnu double/twin sobu i prevoz od aerodroma do hotela. Očekuju vas čista, dobro održavana vozila, marke Mercedes E klase ili Škoda Superb. Sa našim vozačima uvek ćete dobiti udobnu vožnju bez stresa. Vozite sa sa sigurnošću i stilom.",
          "standard_facilities_text": "Moderne sobe su opremljene LCD satelitskim televizorom, klimom, sefom i mini barom. Gostima je na raspolaganju i kupatilo s tušem, fenom za kosu i vrhunskim priborom za kupatilo.",
          "special_offer_desc2": "Ponuda uključuje standardnu double/twin sobu i prevoz od aerodroma do hotela. Očekuju vas čista, dobro održavana vozila, marke Mercedes E klase ili Škoda Superb. Sa našim vozačima uvek ćete dobiti udobnu vožnju bez stresa. Vozite sa sa sigurnošću i stilom.",
          "amazing_rooms": "Neverovatne sobe",
          "dadicated_staffs": "Posvećeno osoblje",
          "stays": "Boravci",
          "places_to_visit": "Mesta za posetiti",
          "daily_cleaning": "Dnevno čišćenje",
          "airport_taxi": "Prevoz do aerodroma",
          "our_services": "Naše usluge",
          "what_we_offer_for_you": "Šta vam nudimo",
          "check_in": "Check - In",
          "check_out": "Check - out",
          "guests": "Gosti",
          "children": "DECA",
          "free_wifi": "Besplatan Wifi",
          "air_condition": "Klima uredjaj",
          "minibar": "Minibar",
          "flatscreen": "TV sa ravnim ekranom",
          "safety_deposit_box": "Sef",
          "electric_kettle_slippers_towels": "Kuvalo, Papuče, Peškiri",
          "free_toiletries": "Besplatna toaleta",
          "free_coffee_tea": "Besplatna kafa i čaj",
          "double_or_twin_desc_short": "Standardna soba, modernog dizajna prostrana je soba veličine 20m²",
          "special_offer_desc_short": "Ponuda uključuje standardnu double/twin sobu i prevoz od aerodroma do hotela.",
          "useful_links": "Korisini Linkovi",
          "parking_extra_fee": "Parking (doplata)",
          "Accommodates: 2": "Broj gostiju: 2",
          "Bathrooms: 1": "Kupatila: 1",
          "Bedroom: 1": "Sobe: 1",
          "Beds: 1 or 2 ( size 160x200 or 2x 90x200)": "Kreveti: 1 ili 2 ( veličine 160x200 ili 2x 90x200)",
          "Check In: 2:00 p.m.": "Prijava: 14h",
          "Check Out: 11:00 a.m.": "Odjava: 11h",
          "Property Type: Hotel Room": "Tip sobe: Hotelska soba",
          "Beds: 1 (160x200)": "Kreveti: 1 (160x200)",
          "Beds: 1 queen size bed (160x200) and 1 sofa bed": "Kreveti: 1 krevet (160x200) i 1 sofa na razvlačenje",
          "First Name": "Ime",
          "Lastname": "Prezime",
          "Email": "Email",
          "Subject": "Naslov",
          "Message": "Tekst",
          "Send Message": "Pošalji",
          "safarikova": "Šafarikova 11, prvi sprat, Beograd, Srbija",
          "Please enter your name": "Molimo unesite vaše ime",
          "Please enter your email": "Molimo unesite vaš email",
          "Please enter your subject": "Molimo unesite naslov",
          "Please enter your Lastname": "Molimo unesite vaše prezime",
          "Please enter your message": "Molimo unesite vašu poruku"
        }
      }
    },
    lng: localStorage.getItem('lang') || "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });


const App = () => { 

  return (
    <div className="App" id='scrool'>
          <AllRoute/>
          <ToastContainer/>
    </div>
  );
}

export default App;
