import React from 'react'
import Logo from '../../images/logo.jpg'
import Viber from '../../images/viber.png'
import Whatsapp from '../../images/whatsapp.png'
import {Link, useLocation}  from 'react-router-dom'
import MobileMenu from '../../components/MobileMenu'
import './style.css'
import { roomTypes } from '../RoomSection'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

const Header = (props) => {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const ClickHandler = () =>{
       window.scrollTo(10, 0);
    }
    const handleLaguageChange = () => {
        const lang = i18n.language === "rs" ? "en" : "rs";
        localStorage.setItem('lang', lang);
        i18n.changeLanguage(lang);
    }   
    return(	
        <div className="middle-header">
            <div className={`header-style-3 ${props.hClass}`}>
                <div className="container-fluid">
                    <div className="header-content">
                    <div className="row align-items-center">
                        <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4">
                            <div className="logo">
                                <Link onClick={ClickHandler} to="/home"><img src={Logo} alt="logo"/></Link>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-8 d-lg-block d-none">
                            <nav>
                                <ul>
                                    <li><Link onClick={ClickHandler} className={location.pathname === '/home' || location.pathname === '/' ? 'active' : ''} to="/home">{t('home')}</Link></li>
                                    <li><Link onClick={ClickHandler} className={location.pathname === '/rooms' ? 'active' : ''} to="/rooms">{t('rooms')} +</Link>
                                        <ul>
                                            {roomTypes.map((type, index) => (
                                                <Fragment key={index}>
                                                    <li><Link onClick={ClickHandler} to={'/rooms/' + type}>{t(type)}</Link></li>
                                                </Fragment>
                                            ))}
                                        </ul>
                                    </li>
                                    <li><Link onClick={ClickHandler} className={location.pathname === '/about' ? 'active' : ''} to="/about">{t('about_us')}</Link></li>
                                    <li><Link onClick={ClickHandler} className={location.pathname === '/contact' ? 'active' : ''} to="/contact">{t('contact')}</Link></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-xl-4 col-md-6 col-sm-7 col-7 get-q row align-items-center justify-content-md-end">
                            <div className="get-quote">
                                <a href="viber://chat/?number=+381600339331"><img src={Viber} alt="viber"/></a>
                            </div>
                            <div className="get-quote">
                                <a href="https://api.whatsapp.com/send?phone=00381600339331"><img src={Whatsapp} alt="whatsapp"/></a>
                            </div>
                            <div className="get-quote">| &nbsp; &nbsp;</div>
                            <div className={`language-selector ${i18n.language}`} onClick={handleLaguageChange}></div>
                        </div>
                        <div className="col">
                            <MobileMenu/>
                        </div>
                    </div>
                    
                        <div className="clearfix"></div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Header;