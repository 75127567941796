import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.jpg'
import specialOfferImg from '../../images/room/specialoffer.png'
import limitedOfferImg from '../../images/room/limitedoffer.png'
import { rooms } from '../RoomSection'
import './style.css'
import { useTranslation } from 'react-i18next'

const Footer = (props) => {
    const { t } = useTranslation();

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const specialOffer = rooms.find(room => room.type === 'special_offer');
    const limitedOffer = rooms.find(room => room.type === 'limited_offer');

    return (
        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="wpo-footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 custom-grid">
                                <div className="logo widget-title">
                                    <img src={Logo} alt="logo" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 custom-grid">
                                <ul>
                                    <li><a href="https://www.facebook.com/Venetisuites"><i className="ti-facebook"></i></a></li>
                                    <li><a href="https://www.instagram.com/veneti_suites_belgrade"><i className="ti-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="widget market-widget wpo-service-link-widget">
                                    <div className="widget-title">
                                        <h3>{t('contact')}</h3>
                                    </div>
                                    <p>{t('visit_us')}</p>
                                    <div className="contact-ft">
                                        <ul>
                                            <li><i className="fi ti-location-pin"></i>{t('safarikova')}</li>
                                            <li><i className="fi ti-mobile"></i><a style={{color: '#fff'}} href='tel:+381114038763'>+381 11 403 87 63</a></li>
                                            <li><i className="fi ti-mobile"></i><a style={{color: '#fff'}} href='tel:+381600339331'>+381 60 03 39 331</a></li>
                                            <li><i className="fi flaticon-email"></i><a style={{color: '#fff'}} href='mailto:office@veneti.rs'>office@veneti.rs</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="widget link-widget resource-widget">
                                    <div className="widget-title">
                                        <h3>{t('rooms')}</h3>
                                    </div>
                                    <Link onClick={ClickHandler} to="/rooms/special_offer">
                                        <div className="news-wrap">
                                            <div className="news-img">
                                                <img src={specialOfferImg} alt="" />
                                            </div>
                                            <div className="news-text">
                                                <h3>{t(specialOffer.type)}</h3>
                                                <span>{t(specialOffer.type + '_desc_short')}</span>
                                                <h2>{specialOffer.price}</h2>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link onClick={ClickHandler} to="/rooms/limited_offer">
                                        <div className="news-wrap">
                                            <div className="news-img">
                                                <img src={limitedOfferImg} alt="" />
                                            </div>
                                            <div className="news-text">
                                                <h3>{t(limitedOffer.type)}</h3>
                                                <span>{t(limitedOffer.type + '_desc_short')}</span>
                                                <h2>{limitedOffer.price}</h2>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                                <div className="widget link-widget">
                                    <div className="widget-title">
                                        <h3>{t('useful_links')}</h3>
                                    </div>
                                    <ul>
                                        <li><Link onClick={ClickHandler} to="/about">{t("about_us")}</Link></li>
                                        <li><Link onClick={ClickHandler} to="/rooms">{t("rooms")}</Link></li>
                                        <li><Link onClick={ClickHandler} to="/contact">{t("contact")}</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-6 col-md-6 col-12"></div>
                        <div className="col col-lg-6 col-md-6 col-12">
                            <p className="copyright">&copy; 2023 Veneti. All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;