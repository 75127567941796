import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';


class ContactForm extends Component {
    state = {
        name: '',
        email: '',
        subject: '',
        lastname: '',
        message: '',
        error: {}
    }

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    subimtHandler = (e) => {
        e.preventDefault();

        const { name,
            email,
            subject,
            lastname,
            message,
            error } = this.state;
        let valid = true;

        if (name === '') {
            error.name = this.props.t("Please enter your name");
            valid = false;
        }
        if (email === '') {
            error.email = this.props.t("Please enter your email");
            valid = false;
        }
        if (subject === '') {
            error.subject = this.props.t("Please enter your subject");
            valid = false;
        }
        if (lastname === '') {
            error.lastname = this.props.t("Please enter your Lastname");
            valid = false;
        }
        if (message === '') {
            error.message = this.props.t("Please enter your message");
            valid = false;
        }
        if (!valid) {
            this.setState({error});
            return;
        } else {
            this.setState ({
                name: '',
                email: '',
                subject: '',
                lastname: '',
                message: '',
                error: {}
            });
        }
        this.props.emailjs.sendForm('service_90v09ib', 'template_a2yeebk', e.currentTarget, 'A96yP_hZyLcC3r3GB')
        .then((result) => {
            console.log(result.text);
        }, (err) => {
            console.log(err.text);
        });
    }

    render(){
        const { name,
            email,
            subject,
            lastname,
            message,
            error } = this.state;

        return(
            <form onSubmit={this.subimtHandler} className="contact-form">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="form-field">
                            <input value={name} onChange={this.changeHandler} type="text" name="name" placeholder={this.props.t("First Name")}/>
                            <p>{error.name ? error.name : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="form-field">
                            <input value={lastname} onChange={this.changeHandler} type="text" name="lastname" placeholder={this.props.t("Lastname")}/>
                            <p>{error.lastname ? error.lastname : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={email} type="email" name="email" placeholder={this.props.t("Email")}/>
                            <p>{error.email ? error.email : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={subject} type="text" name="subject" placeholder={this.props.t("Subject")}/>
                            <p>{error.subject ? error.subject : ''}</p>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-field">
                            <textarea onChange={this.changeHandler} name="message" placeholder={this.props.t("Message")} value={message}></textarea>
                            <p>{error.message ? error.message : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-submit">
                            <button type="submit" className="theme-btn">{this.props.t("Send Message")}</button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

}
export default  withTranslation()(ContactForm);