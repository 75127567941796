
import { t } from "i18next";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { space } from "./description";

const SearchSection = (props) => {
    const { id } = useParams();
    const room = space[id];
    const submitHandler = (e) =>{
        e.preventDefault()
    }
    
    return(
        <div className="wpo-select-section-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="wpo-select-wrap">
                            <div className="wpo-select-area">
                                <form onSubmit={submitHandler} className="clearfix">
                                    {room.filter((item, index) => index < 4).map((item, index) => (
                                        <div key={index} className="select-sub">
                                            <span>{item.split(':')[0]}</span>
                                            <h2>{item.split(':')[1]}</h2>
                                        </div>
                                    ))}
                                    <div className="select-sub">
                                        <button className="theme-btn-s2" type="submit">{t("check_availability")}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchSection;